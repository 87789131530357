import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

const CuerpoCarga = (props) => {
    return (<>
        {props.cargando?<div className="global-cargando"><ProgressSpinner className='loader-img' /></div>:props.children}
        </>
    )
}

export default CuerpoCarga;