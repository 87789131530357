/**
 * An async fetch with error catch
 * @param url
 * @param data
 * @returns {Promise.<*>}
 */
export const request = async (url, data = {}) => {
    try {
      const response = await fetch(url, data);
      return response;
    } catch (err) {
      console.log(err);
      return { error: err };
    }
};

/*
* Funcion para obtener la fecha y hora actual para mysql
*/
export const fechayhoraactual = () => {
  return new Date().toISOString().slice(0, 19).replace('T', ' ');
}
