const Constantes = {
    tituloSitio: "Administración",
    sitename: "administrador",
    estadosPublicado: ["Publicado", "No Publicado"],
    estadoActivo: "ACT",
    estadoInactivo: "INA",
    estRegistro: [
        {label: 'Activo', value: 'ACT'},
        {label: 'Inactivo', value: 'INA'}
    ],
    NOMBRECKUSUARIO:'CRUSUARIOWEB',
    NOMBRECKCOOKIE:'CRCOOKIEUSUARIOWEB',
    NOMBRECKMATERIA:'CRMATERIAWEB',
    NOMBRECKOTRASMATERIAS:'CRMATERIASWEB',
    NOMBRECKAVANCE: 'CRUSUARIOAVANCEWEB',
    NOMBRECKNOTIFICACIONES: 'CRUSUARIONOTIF',
    LSDATOSMATERIA:'LSDATOSMATERIA',
    SI:"Si",
    NO:"No"
}

export default Constantes;