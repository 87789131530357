import React, { Suspense, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Constantes, Config } from './comun';
import {
  BrowserRouter as Router, Routes, Route
} from "react-router-dom";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import Inicio from './publico/Inicio';
import FrontServiciosAPI from './servicios/FrontServicios';

const Layout = React.lazy(() => import('./privado/Layout'));
const Registro = React.lazy(() => import('./publico/Registro'));
const Iframe = React.lazy(() => import('./comun/Iframe'));

function App() {
  const [cookies, setCookie] = useCookies(['user']);

  useEffect(() => {
    const validaLoginApi = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.get('api')!==''){
        const response = await FrontServiciosAPI.postFront('entrar',{apikey: urlParams.get('api')});
          if(!response.ok){
              const errormsj = await response.json();
              console.log('error login api ',errormsj)
          }else{
              let datosUsuario = await response.json();
              //console.log('usuario',datosUsuario);
              //datosUsuario.usuario.datos = JSON.parse(datosUsuario.usuario.datos);
              setCookie(Constantes.NOMBRECKUSUARIO, datosUsuario.estudiante, { path: '/' });
              setCookie(Constantes.NOMBRECKCOOKIE, datosUsuario.cookie, { path: '/' });
              setCookie(Constantes.NOMBRECKOTRASMATERIAS, datosUsuario.materias, { path: '/' });
              
              window.location.reload(false);
          }
      }
    }
    if(!cookies.CRUSUARIOWEB){
      validaLoginApi()
    }
    
  },[])

  document.title=cookies.CRUSUARIOWEB?cookies[Constantes.NOMBRECKMATERIA].nombre:'';
  if(cookies.CRUSUARIOWEB){
    document.getElementById('favicon').href=`${Config.cloudFront}${cookies[Constantes.NOMBRECKMATERIA].id}/recursos/favicon.png`;
  }
  return (
    <Router>
        {cookies.CRUSUARIOWEB ? <Suspense fallback={<div>Cargando...</div>}>
        <Layout />
      </Suspense>:
      <Routes>
          <Route path="/registroexitoso" element={cookies.CRMATERIAWEB && <Suspense fallback={<div>Cargando...</div>}>
              <Iframe url={Config.cloudFront+cookies[Constantes.NOMBRECKMATERIA].id+'/paginaregistro/exito.html'} />
            </Suspense>} />
          <Route path="/registro" element={<Suspense fallback={<div>Cargando...</div>}>
              <Registro />
            </Suspense>} />
          <Route path="/" element={<Inicio />} />
        </Routes>}
    </Router>
  );
}

export default App;
