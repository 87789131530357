import { request } from'./Omni';
import { Config } from '../comun';

const FrontServiciosAPI = {
    getFront: async (pagina) => {
        const _url = `${Config.apiGateway}/frontpublico/${pagina}`;
        return request(_url, {
          method: "GET",
          headers: {"Content-Type": "application/json"},
        });
    },
    postFront: async (pagina,parametros) => {
        const _url = `${Config.apiGateway}/frontpublico/${pagina}`;
        return request(_url, {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(parametros),
        });
    },
    getFrontPriv: async (cookieusuario,pagina) => {
      const _url = `${Config.apiGateway}/frontprivado/${pagina}`;
      return request(_url, {
        method: "GET",
        headers: {"Content-Type": "application/json", "authorization":cookieusuario},
      });
    },
    postFrontPriv: async (cookieusuario,pagina,parametros) => {
        const _url = `${Config.apiGateway}/frontprivado/${pagina}`;
        return request(_url, {
          method: "POST",
          headers: {"Content-Type": "application/json", "authorization":cookieusuario},
          body: JSON.stringify(parametros),
        });
    },
}

export default FrontServiciosAPI;